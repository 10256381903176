import { useState } from 'react';
import Logo from '../Logo/animated'
import { Divider, Drawer } from 'antd';
import MenuButton from './menuButtons';
import MenuPhoneButton from './phoneButtons';
import router from '../../helpers/router';
import root from '../..';
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
    return (
        <nav className=" rounded-3xl w-full flex relative justify-between items-center mx-auto px-0 md:px-10 ">
  {/* logo */}
  <div className="inline-flex">
    <a className="_o6689fn" href="/">
      <div className="block">
    <Logo/>
      </div>
      
    </a>
  </div>
  {/* end logo */}
  {/* search bar */}
  
  <div className="hidden md:block flex-shrink flex-grow-0 justify-start px-2">
    <div className="inline-block">
      <div className="inline-flex items-center max-w-full">
        <button
          className="flex items-center flex-grow-0 flex-shrink pl-2 relative w-80 border rounded-full px-1  py-1"
          type="button"
        >
          <div className="block flex-grow flex-shrink overflow-hidden">
            Start your search
          </div>
          <div className="flex items-center justify-center relative  h-8 w-8 rounded-full">
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                fill: "none",
                height: 12,
                width: 12,
                stroke: "currentcolor",
                strokeWidth: "5.33333",
                overflow: "visible"
              }}
            >
              <g fill="none">
                <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9" />
              </g>
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
  {/* end search bar */}
  {/* login */}
  <div className="flex-initial ">
    <div className="flex justify-end items-center relative">
    <div className="flex mr-4 items-center hidden md:block">
        <a
          className="inline-block bg-[#85cdae80] cursor-pointer transition-all duration-75 mx-2 py-2 px-3 hover:bg-gray-200 rounded-full"
          onClick={(e)=>{router(root, "courses")}}
        >
          <div className=" flex items-center relative  whitespace-nowrap">
            Courses
          </div>
        </a>
        <a
          className="inline-block bg-[#85cdae80] cursor-pointer transition-all duration-75 mx-2 py-2 px-3 hover:bg-gray-200 rounded-full"
          onClick={(e)=>{router(root, "resourses")}}

        >
          <div className=" flex items-center relative  whitespace-nowrap">
            Resources
          </div>
        </a>
        <a
          className="inline-block bg-[#85cdae80] cursor-pointer transition-all duration-75 mx-2 py-2 px-3 hover:bg-gray-200 rounded-full"
          onClick={(e)=>{router(root, "exams")}}

        >
          <div className=" flex items-center relative  whitespace-nowrap">
            Exams
          </div>
        </a>
        <a
          className="inline-block bg-[#85cdae80] cursor-pointer mx-2 py-2 px-3 hover:bg-gray-200 rounded-full"
          onClick={(e)=>{router(root, "events")}}

        >
          <div className="flex items-center relative  whitespace-nowrap">
            Events
          </div>
        </a>
        <a
          className="inline-block bg-[#85cdae80] cursor-pointer mx-2 py-2 px-3 hover:bg-gray-200 rounded-full"
          onClick={(e)=>{router(root, "news")}}

        >
          <div className="flex items-center relative  whitespace-nowrap">
          News
          </div>
        </a>
      </div>
      
      <div className="block mr-4" >
        <div className="inline relative">
          <button
            type="button"
            onClick={(e)=>{toggleMenu()}}
            className="inline-flex items-center relative px-2 border rounded-full bg-[#6863ac50] hover:shadow-lg"
          >
            <div className="pl-1">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{
                  display: "block",
                  fill: "none",
                  height: 16,
                  width: 16,
                  stroke: "currentcolor",
                  strokeWidth: 3,
                  overflow: "visible"
                }}
              >
                <g fill="none" fillRule="nonzero">
                  <path d="m2 16h28" />
                  <path d="m2 24h28" />
                  <path d="m2 8h28" />
                </g>
              </svg>
            </div>
            <div className="block flex-grow-0 flex-shrink-0 h-10 w-12 pl-5">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{
                  display: "block",
                  height: "100%",
                  width: "100%",
                  fill: "currentcolor"
                }}
              >
                <path d="m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* end login */}
  <Drawer className='rounded-l-3xl' onClose={toggleMenu} open={isOpen}>
        
        <div className='block md:hidden'>
        {MenuPhoneButton("Courses", "courses")}
        {MenuPhoneButton("Resources", "resources")}
        {MenuPhoneButton("Exams", "exams")}
        {MenuPhoneButton("Events", "events")}
        {MenuPhoneButton("News", "news")}
        <Divider></Divider>
        </div>

        {MenuButton("Login", ["auth", "login"], "primary")}
        {MenuButton("Sign Up", ["auth", "signup"], "secondary")}
      </Drawer>
</nav>

    )
}

export default Navbar