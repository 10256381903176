import root from "../.."
import Navbar from "../../components/Navbar"
import email from "../../media/email.png"
import call from "../../media/call.png"
import facebook from "../../media/facebook.png"
import instagram from "../../media/instagram.png"
import linkedin from "../../media/linkedin.png"
import backgroundImageHomepage from "../../media/hbg.jpg"
import router from "../../helpers/router"
import Footer from "../../components/Footer"
const TermsConditions = () => {
  
    return (
        <>
      
       <section  className="bg-gray-500 md:px-8 px-2  h-screen overflow-x-hidden" >
       <div className=" ">
<br></br>

       <div  className="rounded-3xl mb-5 pb-24 md-0  align-middle bg-white"  style={{backgroundImage: `url("${backgroundImageHomepage}")`}}>
       <div className="pt-4">
       <Navbar/>
       </div>


     {/* CONTENT START */}

     <div className="max-w-4xl mx-auto px-4 py-8">
  <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
  <p className="text-sm text-gray-600 mb-6">Last Updated: 18th August, 2024</p>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">1. User Responsibilities</h2>
    <p className="text-gray-700">
      Users must keep their identity safe, including passwords and subscription information. Users are prohibited from leaking premium content to non-premium customers or third parties. With a paid subscription, users can join courses, take exams, receive certifications, and read 100% authentic news from around the globe.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">2. Content Ownership</h2>
    <p className="text-gray-700">
      Users own 100% of the content they upload. We reserve the right to delete or ban users. In cases involving law enforcement, we may access user data.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">3. Prohibited Activities</h2>
    <p className="text-gray-700">
      The following activities are prohibited:
    </p>
    <ul className="list-disc list-inside ml-4 text-gray-700">
      <li>Sharing premium content without proper copyright and brand logo.</li>
      <li>Attempting to breach the website's database and APIs.</li>
      <li>Spamming or hacking other user accounts.</li>
      <li>Broadcasting premium resources or sharing ID and password with others.</li>
      <li>Selling courses from our site for a higher fee.</li>
      <li>Any other activities that violate the website’s terms.</li>
    </ul>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">4. Termination of Service</h2>
    <p className="text-gray-700">
      We reserve the right to terminate accounts if illegal payment methods are used, such as terrorist bank accounts, hijacked cards or accounts, stolen cards, or for any activities mentioned in section 3.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">5. User Actions</h2>
    <p className="text-gray-700">
      Users are permitted to take actions within the boundaries of the website’s rules and terms.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">6. Data Access</h2>
    <p className="text-gray-700">
      We may access user data if required by the government or in connection with a legal case. However, we do not share personal data with third parties, including the government.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">7. Updates to Terms</h2>
    <p className="text-gray-700">
      Any updates to these Terms and Conditions will be communicated via email. Please ensure your email address is up to date.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">8. Contact Information</h2>
    <p className="text-gray-700">
      If you have any questions about these terms, please contact us at:
    </p>
    <p className="text-gray-700">
      <strong>Email:</strong> <a href="mailto:legal@threesea.net" className="text-blue-600 underline">legal@threesea.net</a>
    </p>
  </div>
</div>






     {/* CONTENT END */}





      
<Footer/>
       

</div>

       </div>
      
       </section>
        </>
    )

}

export default TermsConditions