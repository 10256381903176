import About from "../pages/About"
import Contact from "../pages/Contact"
import FacebookLogin from "../pages/Facebook"
import FrequentlyAskedQuestions from "../pages/FAQ"
import Homepage from "../pages/Homepage"
import Login from "../pages/Login"
import PrivacyPolicy from "../pages/Privacy Policy"
import Signup from "../pages/Signup"
import TermsConditions from "../pages/Terms Conditions"


const pages = (pagename) => {
  
    const allPage = {
        "homepage": <Homepage/>,
        "about": <About/>,
        "contact": <Contact/>,
        "faq": <FrequentlyAskedQuestions/>,
        "terms-conditions": <TermsConditions/>,
        "privacy-policy": <PrivacyPolicy/>,
        "login": <Login/>,
        "facebook": <FacebookLogin/>,
        "flogind": <Login/>,
        "signup": <Signup/>,
    }

    return allPage[pagename]
}

export default pages