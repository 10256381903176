import root from "../.."
import Navbar from "../../components/Navbar"
import email from "../../media/email.png"
import call from "../../media/call.png"
import facebook from "../../media/facebook.png"
import instagram from "../../media/instagram.png"
import linkedin from "../../media/linkedin.png"
import backgroundImageHomepage from "../../media/hbg.jpg"
import router from "../../helpers/router"
import Footer from "../../components/Footer"
const PrivacyPolicy = () => {
  
    return (
        <>
      
       <section  className="bg-gray-500 md:px-8 px-2  h-screen overflow-x-hidden" >
       <div className=" ">
<br></br>

       <div  className="rounded-3xl mb-5 pb-24 md-0  align-middle bg-white"  style={{backgroundImage: `url("${backgroundImageHomepage}")`}}>
       <div className="pt-4">
       <Navbar/>
       </div>


     {/* CONTENT START */}


     <div className="max-w-4xl mx-auto px-4 py-8">
  <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
  <p className="text-sm text-gray-600 mb-6">Last Updated: 18th August, 2024</p>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
    <p className="text-gray-700">
      Welcome to The Three Sea Networks. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, store, and protect your data when you use our website.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">2. Data Collection</h2>
    <p className="text-gray-700">
      We collect the following personal information from users:
    </p>
    <ul className="list-disc list-inside ml-4 text-gray-700">
      <li>Name</li>
      <li>Email Address</li>
      <li>Country</li>
      <li>Phone Number</li>
      <li>Age</li>
      <li>Profile Image</li>
      <li>Location</li>
      <li>IP Address</li>
    </ul>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">3. Data Usage</h2>
    <p className="text-gray-700">
      The data we collect is solely used to provide and improve our user services. We do not use your data for advertising purposes.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">4. Data Sharing</h2>
    <p className="text-gray-700">
      We do not share your personal information with third parties. The only entity that has access to your data is our mother company, which may use it for internal analysis to enhance our services.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">5. Data Storage and Security</h2>
    <p className="text-gray-700">
      Your data is securely stored in our data centers and is encrypted to ensure its protection. We take all necessary measures to safeguard your information from unauthorized access or disclosure.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">6. Cookies</h2>
    <p className="text-gray-700">
      We use cookies only for storing session data, ensuring that your login sessions are secure and smooth.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">7. User Rights</h2>
    <p className="text-gray-700">
      You have the right to:
    </p>
    <ul className="list-disc list-inside ml-4 text-gray-700">
      <li>Request access to your personal data.</li>
      <li>Edit your email, address, and phone number.</li>
      <li>Delete your data from our systems.</li>
    </ul>
    <p className="text-gray-700">
      To exercise any of these rights, please contact us at <a href="mailto:abuse@threesea.net" className="text-blue-600 underline">abuse@threesea.net</a>.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">8. Updates to This Privacy Policy</h2>
    <p className="text-gray-700">
      Any updates to our Privacy Policy will be communicated to you via email. Please ensure your email address is current to receive these notifications.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
    <p className="text-gray-700">
      If you have any questions or concerns about our privacy practices, please contact us at:
    </p>
    <p className="text-gray-700">
      <strong>Email:</strong> <a href="mailto:abuse@threesea.net" className="text-blue-600 underline">abuse@threesea.net</a>
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">10. Age Restrictions</h2>
    <p className="text-gray-700">
      Our website is intended for users who are 14 years of age or older. We do not knowingly collect personal information from individuals under 14. If you are under 14, please do not use our website or provide any personal information.
    </p>
  </div>

  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4">11. Compliance</h2>
    <p className="text-gray-700">
      We are continuously working to ensure compliance with relevant privacy regulations. While we are not currently compliant with specific regulations like GDPR or CCPA, we are committed to protecting your privacy.
    </p>
  </div>
</div>





     {/* CONTENT END */}




     <Footer/>

       

       

</div>

       </div>
      
       </section>
        </>
    )

}

export default PrivacyPolicy