import NotFound from "../pages/Not Found";
import pages from "./pages";

const router = (dom, slug) => {
    
    const page = typeof(slug) === "string" ? slug : slug[0]
    window.history.pushState("", "", `/${page}`)

        switch(page.toLowerCase()){
            case 'auth':
                switch(slug[1]){
                    default:
                        dom.render(pages(slug[1]))
                    break;
                    case undefined:
                        dom.render(<NotFound/>)
                    break;
                }
            break;
            
            case 'dashboard':
                
            break;

            case '':
                dom.render(pages("homepage"))
            break;
            case 'faq':
                dom.render(pages("faq"))
            break;
            case 'about':
                dom.render(pages("about"))
            break;
            case 'privacy-policy':
                dom.render(pages("privacy-policy"))
            break;
            case 'contact':
                dom.render(pages("contact"))
            break;
            case 'terms-conditions':
                dom.render(pages("terms-conditions"))
            break;

            default:
                dom.render(<NotFound/>)
            break;
        }
}

export default router