import root from "../.."
import Navbar from "../../components/Navbar"
import dood1 from "../../media/a1.svg"
import dood2 from "../../media/a2.svg"
import dood3 from "../../media/a3.svg"
import backgroundImageHomepage from "../../media/hbg.jpg"
import router from "../../helpers/router"
import Footer from "../../components/Footer"
const About = () => {

  
    return (
        <>
      
       <section  className="bg-gray-500 md:px-8 px-2  h-screen overflow-x-hidden" >
       <div className=" ">
<br></br>

       <div  className="rounded-3xl mb-5 pb-24 md-0  align-middle bg-white"  style={{backgroundImage: `url("${backgroundImageHomepage}")`}}>
       <div className="pt-4">
       <Navbar/>
       </div>


     {/* CONTENT START */}


     <section className="py-20 bg-white">
  <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
    <div className="relative">
      <h2 className="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">
        {" "}
        Level Up Your Skills
      </h2>
      <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">
        The Three Sea Network is a network for people who are enthusiastic for knowledge and who loves to build a perfect profile for their career.
        Here, we provide hands on tutorial and on demand video lectures with interactive lessions and resources. Starting January 6th, 2023 TTSN has been providing 
        carefully crafted tutorials and resources to all the students and professionals who are interested in learning new skills.
      </p>
    </div>
    <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
      <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
        <img
          className="rounded-lg shadow-xl"
          src={dood1}
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
          Visual Simplicity 
        </p>
        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
          Non Distracting UI with Interactivity
        </h3>
        <p className="mt-5 text-justify text-lg text-gray-700 text md:text-left">
         We ensure that our readers are only focusing on what is in front of him. We remove any distracting objects from the scene when you're studying.
         We also have interactional sections where you can put on the things together what you are learning.
        </p>
      </div>
    </div>
    <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
      <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
        <img
          className="rounded-lg shadow-xl"
          src={dood2}
           alt=""
        />
      </div>
      <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
          know your ability
        </p>
        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
          Assesments that Matter
        </h3>
        <p className="mt-5 text-justify text-lg text-gray-700 text md:text-left">
        Backed by fully professional personnel behind the scenes, each and eevery Tests and Exams are tailored just for you based on your activities and performance,
        Helping you grow your skills along with having a control over your learning capabilities.
        </p>
      </div>
    </div>
    <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
      <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
        <img
          className="rounded-lg shadow-xl"
          src={dood3}
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
          The power of Togetherness 
        </p>
        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
          Learn with your friends and network
        </h3>
        <p className="mt-5 text-justify text-lg text-gray-700 text md:text-left">
          TTSN provides All in One platform for you to showcase and utilize your skills. Share your knowledge with your friends and colleagues or get an actual job based on your
          TTSN Certified E-Academic Transcript.
        </p>
      </div>
    </div>
  </div>
</section>





     {/* CONTENT END */}



     <Footer/>


      

       

</div>

       </div>
      
       </section>
        </>
    )

}

export default About