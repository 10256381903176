import root from "../.."
import Navbar from "../../components/Navbar"
import ECIcon from "../../media/E&C Icon.png"
import dood1 from "../../media/dood1.svg"
import dood2 from "../../media/dood2.png"
import v01 from "../../media/v01.jpg"
import backgroundImageHomepage from "../../media/hbg.jpg"
import router from "../../helpers/router"
import Footer from "../../components/Footer"
const Homepage = () => {
  
    return (
        <>
      
       <section  className="bg-gray-500 md:px-8 px-2  h-screen overflow-x-hidden" >
       <div  className=" ">
<br></br>

       <div  className="rounded-3xl mb-5 pb-24 md-0  align-middle bg-white"  style={{backgroundImage: `url("${backgroundImageHomepage}")`}}>
       <div className="pt-4">
       <Navbar/>
       </div>
       <span className="block md:hidden text-4xl text-center">
                <img src={v01} className="w-90 mb-4 md:w-48 " />
             
                Self Development
            </span>
        <h1 className=" hidden md:block text-4xl md:text-6xl md:flex items=start md:items-center justify=start md:justify-center text-start md:text-center p-4 md:p-0 mt-20 font-black">
            Self 
            <span className="hidden md:block">
                <img src={v01} className="h-24   md:w-48 md:mx-4 my-4 my-0 rounded-full" />
                    
            </span>
             Development 
        </h1>
       <center>

       </center>
        <h1 className="text-4xl md:text-6xl flex items=end md:items-center justify=end md:justify-center text-right md:text-center p-4 md:p-0 font-black">
            Trainings
            <span>
                <img draggable={false} src={ECIcon} className=" block ml-auto -rotate-90 md:rotate-0 mb-4 md:my-4 my-0 md:w-48 md:mx-4 t-up"/>
            </span>
            Exams
        </h1>
        <p className="text-center md:mt-8 md:px-0 px-4 font-sans">Get the best self learning resources and one and one trainings to enhance your knowledge</p>
<center>
<div>
  <form className="m-2 md:m-4">
    
    <button onClick={(e)=>{router(root, ["auth","signup"])}} className="md:px-8 px-2 rounded-lg bg-[#6863ac]  text-white font-bold p-2 md:p-4 uppercase ">
      Register
    </button>
  </form>
  <br></br>
  {/* <button onClick={()=>{scrollToSecondDiv()}} className="group relative h-12 w-24 md:h-12 md:w-48 overflow-hidden rounded-2xl text-lg font-bold text-black">
  Browse
  <div className="absolute  inset-0 h-full w-full scale-0 rounded-2xl transition-all duration-300 group-hover:scale-100 group-hover:bg-white/30" />
</button> */}

</div>
<img src={dood1} className="hidden md:block w-16 md:w-48 a-upndown " style={{transform:`translateX(${(window.innerWidth / 100)*25}px) translateY(-${(window.innerHeight / 100)*45}px) rotate(-30deg)`}}/>

<img src={dood2} className="hidden md:block w-16 md:w-48 a-upndown" style={{transform:`translateX(-${(window.innerWidth / 100)*25}px) translateY(-${(window.innerHeight / 100)*45}px) rotate(15deg)`}}/>

</center>

    
<Footer/>

       

</div>

       </div>
      
       </section>
        </>
    )

}

export default Homepage