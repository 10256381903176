import root from "../.."
import router from "../../helpers/router"

const Footer = () =>{
    return(
        <div className="block md:flex ">
        {/*- more free and premium Tailwind CSS components at https://tailwinduikit.com/ -*/}
       <div className=" md:p-16  rounded-3xl">
        <div className="container m-auto px-2 space-y-8 text-gray-500 md:px-12">
          <div>
            <span className="text-gray-600 text-lg font-semibold">What we are known for</span>
            <h2 className="mt-4 text-2xl text-gray-900 font-bold md:text-4xl">
              Advancement to Media and Learning Resources
              </h2>
          </div>
          <div className="mt-16 grid border divide-x divide-y rounded-xl overflow-hidden sm:grid-cols-2 lg:divide-y-0 lg:grid-cols-3 xl:grid-cols-4">
            <div className="relative group bg-white transition hover:z-[1] hover:shadow-2xl">
              <div className="relative p-8 space-y-8">
            
                <div className="space-y-2">
                  <h5 className="text-xl text-gray-800 font-medium transition group-hover:text-[#6863ac]">
                    Language Tests
                  </h5>
                  <p className="text-sm text-gray-600">
                    Prepare and Take international English Profeciency Tests from your nearest Three Sea Exam Center
                  </p>
                </div>
                <a
                  href="#"
                  className="flex justify-between items-center group-hover:text-[#6863ac]"
                >
                  <span className="text-sm">Continue</span>
                  <span className="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0">
                    →
                  </span>
                </a>
              </div>
            </div>
            <div className="relative group bg-white transition hover:z-[1] hover:shadow-2xl">
              <div className="relative p-8 space-y-8">
             
                <div className="space-y-2">
                  <h5 className="text-xl text-gray-800 font-medium transition group-hover:text-[#6863ac]">
                    Professional Courses
                  </h5>
                  <p className="text-sm text-gray-600">
                    Enrich your knowledge with on-demand interactive courses suggested and created by highly professionals
                  </p>
                </div>
                <a
                  href="#"
                  className="flex justify-between items-center group-hover:text-[#6863ac]"
                >
                  <span className="text-sm">Continue</span>
                  <span className="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0">
                    →
                  </span>
                </a>
              </div>
            </div>
            <div className="relative group bg-white transition hover:z-[1] hover:shadow-2xl">
              <div className="relative p-8 space-y-8">
              
                <div className="space-y-2">
                  <h5 className="text-xl text-gray-800 font-medium transition group-hover:text-[#6863ac]">
                    Accredited Skill Up Modules
                  </h5>
                  <p className="text-sm text-gray-600">
                    Get accredited by TTSN and build your Professional Career Path with your digital academic Transcript
                  </p>
                </div>
                <a
                  href="#"
                  className="flex justify-between items-center group-hover:text-[#6863ac]"
                >
                  <span className="text-sm">Continue</span>
                  <span className="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0">
                    →
                  </span>
                </a>
              </div>
            </div>
            <div className="relative group bg-gray-100 transition hover:z-[1] hover:shadow-2xl lg:hidden xl:block">
              <div className="relative p-8 space-y-8 border-dashed rounded-lg transition duration-300 group-hover:bg-white group-hover:border group-hover:scale-90">
              
                <div className="space-y-2">
                  <h5 className="text-xl text-gray-800 font-medium transition group-hover:text-[#6863ac]">
                    Media and Networks
                  </h5>
                  <p className="text-sm text-gray-600">
                    TTSN allows you to get exclusive news and updates from the most authentic sources across the globe.
                  </p>
                </div>
                <a
                  href="#"
                  className="flex justify-between items-center group-hover:text-[#6863ac]"
                >
                  <span className="text-sm">Read more</span>
                  <span className="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0">
                    →
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
        <div className="overflow-y scale-90 w-full md:w-4/12 md:-mx-8 block lg:block">
        <div className="md:px-8 ">
      
          <h1 className="mb-4 text-xl font-bold text-gray-700">Additional Pages</h1>
          <div className="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white rounded-lg shadow-md">
            <ul className="-mx-4">
            <li className="flex items-center">
          
          <p>
            <a
                            onClick={(e)=>{router(root, "about")}}
      
              className="mx-1 font-bold text-gray-700 hover:underline cursor-pointer"
            >
              About
            </a>
           
          </p>
        </li>
        <li className="flex items-center mt-6">
          
          <p>
            <a
                            onClick={(e)=>{router(root, "contact")}}
      
              className="mx-1 font-bold text-gray-700 hover:underline cursor-pointer"
            >
              Contact
            </a>
           
          </p>
        </li>
        <li className="flex items-center mt-6">
      
          <p>
            <a
                           onClick={(e)=>{router(root, "terms-conditions")}}
      
              className="mx-1 font-bold text-gray-700 hover:underline cursor-pointer"
            >
              Terms and Conditions
            </a>
            
          </p>
        </li>
        <li className="flex items-center mt-6">
          
          <p>
            <a
                            onClick={(e)=>{router(root, "privacy-policy")}}
      
              className="mx-1 font-bold text-gray-700 hover:underline cursor-pointer"
            >
              Privacy Policy
            </a>
          </p>
        </li>
              <li className="flex items-center mt-6">
                
                <p>
                  <a
                    onClick={(e)=>{router(root, "faq")}}
                    className="mx-1 font-bold text-gray-700 hover:underline cursor-pointer"
                  >
                    FAQ
                  </a>
                  
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="md:px-8 mt-10">
          <h1 className="mb-4 text-xl font-bold text-gray-700">Partners and Affiliates</h1>
          <div className="flex flex-col max-w-sm px-4 py-6 mx-auto bg-white rounded-lg shadow-md">
            <ul>
              <li>
                <a
                  href="https://www.intredia.com"
                  className="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline"
                >
                Intredia LLC
                </a>
              </li>
            
              <li className="flex items-center mt-2">
                <a
                  href="https://www.orioons.cloud"
                  className="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline"
                >
                Orioons Cloud
                </a>
              </li>
              
             
              
              <li className="mt-2">
                <a
                  href="https://www.mediaindustrialdesigns.com"
                  className="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline"
                >
                Media and Industrial Designs
                </a>
              </li>
              
            </ul>
          
          <p className=" mt-4 text-xs font-light">
          Developed by Intredia LLC. Copyright Intredia LLC all rights reserved
      
          </p>
          </div>
        </div>
        
      </div>
      
      </div>
    )
}

export default Footer