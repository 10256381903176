import root from "../.."
import Navbar from "../../components/Navbar"
import backgroundImageHomepage from "../../media/hbg.jpg"
import router from "../../helpers/router"
import Footer from "../../components/Footer"
const FrequentlyAskedQuestions = () => {
 
  
    return (
        <>
      
       <section  className="bg-gray-500 md:px-8 px-2  h-screen overflow-x-hidden" >
       <div  className=" ">
<br></br>

       <div  className="rounded-3xl mb-5 pb-24 md-0  align-middle bg-white"  style={{backgroundImage: `url("${backgroundImageHomepage}")`}}>
       <div className="pt-4">
       <Navbar/>
       </div>


       <div>
  <section className="text-gray-700">
    <div className="container px-5 py-24 mx-auto">
      <div className="text-center mb-20">
        <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          Frequently Asked Question
        </h1>
        <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
          The most common questions about how our business works and what can do
          for you.
        </p>
      </div>
      <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
        <div className="w-full lg:w-1/2 px-4 py-2">
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            What kind of courses do you offer?

</summary>
            <span>
            We offer a wide range of online courses covering various subjects.
             Our curriculum includes Technology, Self Development, Sustainability, Planning, Designing, Language and more.
            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold bg-gray-200 rounded-md py-2 px-4">
            How do I enroll in a course?

</summary>
            <span>
            Enrolling is easy! Just visit our course page, select the desired course, and follow the on-screen instructions to complete the registration process. To enroll, you must:
            <li>Not be a part of any political organization or parties</li>
            <li>Aged more than 16 Years old</li>
            <li>Not belong from Israel</li>
            <li>Not a part of LGBTQ society</li>
            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            What are the requirements for obtaining a certificate?

</summary>
            <span>
            To earn a certificate, you must successfully complete the course and pass the final exam with a minimum passing grade.
            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            How long does it take to complete a course?

</summary>
            <span className="px-4 py-2">
            Course duration varies depending on the subject and level. We offer both self-paced and instructor-led courses to accommodate different learning styles
            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            What kind of tests do you offer?
</summary>
            <span className="px-4 py-2">
            We provide practice tests, certification exams, and assessments to help you evaluate your knowledge and skills.
            </span>
          </details>
        </div>
        <div className="w-full lg:w-1/2 px-4 py-2">
         
         


          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            How can I access the study materials?

</summary>
            <span className="px-4 py-2">
            Study materials, including textbooks, ebooks, and video lectures, are available within the course platform. You can access them anytime, anywhere with an internet connection.            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            What kind of news and media content do you cover?

</summary>
            <span className="px-4 py-2">
            We offer a comprehensive range of news and media content, including global events, politics, business, technology, entertainment, and more.            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
Who has access to Media+ 
</summary>
            <span className="px-4 py-2">
           Only premium subscribers are given access to media and news.
           </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            How do I contact customer support?

</summary>
            <span className="px-4 py-2">
            You can reach our customer support team through email at hello@threesea.net. We are available 24/7            </span>
          </details>
          <details className="mb-4">
            <summary className="font-semibold  bg-gray-200 rounded-md py-2 px-4">
            Is my personal information secure?


</summary>
            <span className="px-4 py-2">
            We prioritize the security of your personal information. We employ industry-standard encryption and security measures to protect your data.      </span>
          </details>
        </div>
      </div>
    </div>
  </section>
</div>





   

<Footer/>


</div>

       </div>
      
       </section>
        </>
    )

}

export default FrequentlyAskedQuestions