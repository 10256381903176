import facebookMobileLogo from '../../media/facebook.png'
import facebookDesktopLogo from '../../media/facebook.svg'

const FacebookLogin = () => {
    return(
        <div className="container flex flex-col items-center mt-40 mx-auto justify-center md:flex-row md:mt-32">
       <center>
       <div className="first w-96 mx-20 md:w-1/3">
          <img src={facebookMobileLogo} alt="Facebook Logo" className="w-24 block md:hidden" />
          <img src={facebookDesktopLogo} alt="Facebook Logo" className="w-80 hidden md:block" />
          <p className="text-xl mx-1 saturate-50 my-3 md:text-2xl">
            Facebook helps you to connect and share with the people in your life.
          </p>
        </div>
       </center>
        <div className="second flex flex-col w-96 bg-white p-8 rounded-lg shadow-md my-0 mt-12 md:w-1/4">
          <input
            className="px-4 h-12 my-1 border border-1 border-gray-300 rounded-lg outline-blue-400"
            type="text"
            name="email"
            id="email"
            placeholder="Email address or phone number"
          />
          <input
            className="px-4 h-12 my-1 border border-1 border-gray-300 rounded-lg outline-blue-400"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
          />
          <button className="bg-blue-600 hover:bg-blue-700 text-white p-3 my-2 rounded-lg font-medium cursor-pointer">
            Log In
          </button>
          <span className="text-blue-500 text-center text-sm my-1 cursor-pointer hover:underline hover:underline-offset-2 font-medium tracking-normal">
            Forgot Password ?
          </span>
          <hr className="my-2" />
          <button className="bg-green-600 hover:bg-green-700 text-white p-3 my-2 rounded-lg font-medium cursor-pointer w-fit mx-auto">
            Create New Account
          </button>
        </div>
      </div>
      

    )
}

export default FacebookLogin