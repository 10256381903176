import root from "../.."
import router from "../../helpers/router"

const NotFound = () => {


    return (
        <>
       <section className="bg-gray-200 p-10 h-screen">
       <div className="rounded-lg h-full w-full p-5 align-middle place-content-center bg-white">

<div className="text-4xl font-bold uppercase text-center ">
    <h1 className="text-6xl text-[#6863ac]">404</h1>
    <p className="text-gray-600">The page you requested for, was not found</p>
    <button onClick={(e)=>{router(root, "")}} className="text-xl rounded-xl m-4 bg-[#85cdae] font-medium p-3 hover:border-r-8 border-[#6863ac] transition-all duration-75">Return to Homepage</button>
</div >
</div>
       </section>
        </>
    )

}

export default NotFound