
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './helpers/router';

const top = ReactDOM.createRoot(document.getElementById('top'));
const root = ReactDOM.createRoot(document.getElementById('root'));
const bottom = ReactDOM.createRoot(document.getElementById('bottom'));

const slug = window.location.pathname.split('/')
slug.splice(0,1)
router(root, slug)


export default root