import hsc from '../../media/hsc.png'
import hbc from '../../media/hbc.png'
import './logo.css'
const Logo = () => {

    return(
<div className='logo-parent flex md:block'>
<img draggable={false} className='w-16 m-4 md:m-0 md:w-16 hidden md:block animated-child' src={window.innerHeight > window.innerWidth ? hsc : hsc}/>
<img draggable={false} className='w-32 m-4 md:m-0 md:w-44  md:block' src={window.innerHeight > window.innerWidth ? hbc : hbc}/>
</div>
    )
}

export default Logo